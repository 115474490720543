export default [
  {
    header: 'Admin',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Contract',
        route: 'jccd-contract',
        icon: 'ShoppingCartIcon',
      },
      {
        title: 'Customer',
        route: 'jccd-customer',
        icon: 'ShoppingCartIcon',
      },
      {
        title: 'Voucher',
        route: 'jccd-voucher',
        icon: 'ShoppingCartIcon',
      },
    ],
  },
]
